import React from "react";
import Banner from "../elements/Banner";
import PageTitle from "../elements/PageTitle";

export default function Impressum() {
  window.addEventListener(
    "load",
    function (e) {
      const script = document.createElement("script");
      script.src =
        "https://consent.cookiebot.com/21eb6ebe-3372-432d-9e40-9d36eac83d70/cd.js";
      script.setAttribute("data-culture", "de");
      document.body.appendChild(script);
    },
    false
  );

  return (
    <React.Fragment>
      <PageTitle
        title="Impressum und Datenschutz"
        description="Impressum und Datenschutz"
        canonical="impressum"
      />
      <div className="page-content bg-white">
        <section className="section-area section-sp1">
          <Banner title="Rechtliches">Impressum und Datenschutz</Banner>
          <div className="container">
            <h2 className="text-center">Impressum</h2>
            <h5>Angaben gem. § 5 TMG</h5>
            <p>
              Verantwortlich für die Inhalte und Impressum von
              www.fliegender-zahn.de:
            </p>
            <br />
            <h5>Betreiber und Kontakt:</h5>
            <p>
              Christine Zarro und Milan Keyhani GbR
              <br />
              Jasperallee 36
              <br />
              38102 Braunschweig
              <br />
              Telefon: 0531 2245 8888
            </p>
            <h5>Berufsbezeichnung:</h5>
            <p>Zahnärzte, verliehen in der Bundesrepublik Deutschland.</p>
            <h5>Zuständige Kammer / Aufsichtsbehörde</h5>
            <p>
              Kassenzahnärztliche Vereinigung Niedersachsen (KZVN)
              <br />
              Zeißstr. 11
              <br />
              30519 Hannover
              <br />
              Tel: 0511 84050
              <br />
              www.kzvn.de
            </p>

            <h5 className="mt-20">Haftung für Inhalte</h5>
            <p>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
              sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
              nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
              sind wir als Diensteanbieter jedoch nicht verpflichtet,
              übermittelte oder gespeicherte fremde Informationen zu überwachen
              oder nach Umständen zu forschen, die auf eine rechtswidrige
              Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen
              bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
              erst ab dem Zeitpunkt der Kenntnis einer konkreten
              Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <h5 className="mt-20">Haftung für Links</h5>
            <p>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
              der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
              Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.
            </p>
            <h5 className="mt-20">Urheberrecht</h5>
            <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.
            </p>

            <h2 className="text-center mt-50">Datenschutz</h2>
            <p>
              <h3>Datenschutzerklärung</h3>
              <p>
                Vielen Dank für Ihr Interesse an unserer Praxis für
                Kieferorthopädie sowie Kinder- und Jugendzahnheilkunde. Unser
                Ziel ist es, die Verarbeitung personenbezogener Daten fair und
                transparent zu gestalten. Nachfolgend informieren wir Sie
                darüber, welche Arten von personenbezogenen Daten wir zu welchen
                Zwecken und in welchem Umfang erheben und verarbeiten. Zudem
                klären wir betroffene Personen über ihre Rechte auf.
              </p>

              <p>
                Verantwortlich für die Verarbeitung der personenbezogenen Daten
                im Sinne der Datenschutz-Grundverordnung (DSGVO) ist die:
              </p>
              <address>
                Christine Zarro und Milan Keyhani GbR
                <br />
                Jasperallee 36
                <br />
                38102 Braunschweig
                <br />
                Telefon: 0531 2245 8888
                <br />
                E-Mail:{" "}
                <a href="mailto:praxis@fliegender-zahn.de">
                  praxis@fliegender-zahn.de
                </a>
              </address>

              <p>
                Für unsere Praxis haben wir einen externen
                Datenschutzbeauftragten bestellt. Sie erreichen ihn wie folgt:
              </p>
              <address>
                Rechtsanwalt Svetoslav Ivanov LL.M.
                <br />
                Arabellastraße 32
                <br />
                81925 München
                <br />
                Telefon: 089 32801134
                <br />
                E-Mail: <a href="mailto:info@ivanov.law">info@ivanov.law</a>
              </address>

              <h3>Begriffsbestimmungen</h3>
              <p>
                „Personenbezogene Daten“ sind alle Informationen, die sich auf
                eine identifizierte oder identifizierbare natürliche Person (im
                Folgenden „betroffene Person“) beziehen (Art. 4 Nr. 1 DSGVO).
              </p>
              <p>
                „Verarbeitung“ bezeichnet jeden Vorgang oder jede Vorgangsreihe,
                die im Zusammenhang mit personenbezogenen Daten steht und mit
                oder ohne Hilfe automatisierter Verfahren ausgeführt wird. Dazu
                gehören unter anderem das Erheben, Erfassen, Organisieren,
                Ordnen, Speichern, Anpassen oder Verändern, Auslesen, Abfragen,
                Verwenden, Offenlegen durch Übermittlung, Verbreiten oder eine
                andere Form der Bereitstellung, der Abgleich oder die
                Verknüpfung, die Einschränkung sowie das Löschen oder Vernichten
                (Art. 4 Nr. 2 DSGVO).
              </p>

              <h3>
                2. Nutzungsdaten und Erfassen allgemeiner Informationen beim
                Verwenden unserer Webseite
              </h3>
              <p>
                Bei jedem Aufruf unserer Webseite können automatisch bestimmte
                allgemeine Informationen erfasst werden. Es handelt sich um
                folgende Informationen:
              </p>
              <ul>
                <li>IP-Adresse</li>
                <li>Betriebssystem</li>
                <li>Verwendete Browser-Software</li>
                <li>Ursprungswebsite</li>
                <li>Aufgerufene Unterseiten</li>
                <li>Datum und Uhrzeit des Website-Aufrufs</li>
                <li>Land und Ort des Nutzers</li>
                <li>Internetdienstanbieter</li>
              </ul>
              <p>
                Bei der Erfassung Ihrer IP-Adresse handelt es sich um eine
                temporäre Speicherung, die notwendig ist, um die Webseite an Ihr
                Endgerät ausliefern zu können. Die Verarbeitung der Daten dient
                dazu, den Zugriff auf die Webseite zu ermöglichen und deren
                Sicherheit und Stabilität zu gewährleisten. Diese Daten werden
                für die Dauer der Erfüllung der entsprechenden Aufgaben
                gespeichert. Die rechtliche Grundlage hierfür ist Art. 6 Abs. 1
                f) DSGVO. Die Verarbeitung ist zur Wahrung der überwiegenden
                berechtigten Interessen des Verantwortlichen erforderlich. Sog.
                „Logfiles“, die eine Identifizierung der betroffenen Personen
                ermöglichen, werden nicht gespeichert.
              </p>

              <h3>
                3. Verwendung von Cookies, Consent Management Platform (CMP)
              </h3>
              <p>
                Ein Cookie ist ein kleiner Datensatz, der beim Besuch einer
                Website auf dem Gerät des Nutzers gespeichert wird. Bei einem
                späteren Besuch derselben Website wird das Cookie vom Browser
                zurück an den Server gesendet. Diese Rückmeldung ermöglicht es
                dem Server, bestimmte Informationen auszuwerten. Cookies
                ermöglichen und/oder erleichtern die Navigation auf der Website
                und verbessern die Benutzererfahrung.
                <br />
                <br />
                Sie können die von unserer Webseite eingesetzten Cookies
                jederzeit einsehen und über unser Cookie-Consent-Tool
                „Cookiebot“ verwalten und ggf. erteilte Einwilligungen einsehen,
                ändern oder widerrufen
              </p>

              <h2 className="text-center mt-50">Cookie-Erklärung</h2>
              <div id="CookieDeclaration"></div>
              <h3>Cookiebot</h3>
              <p>
                Wir nutzen den Dienst „Cookiebot“ der Usercentrics A/S,
                Havnegade 39, 1058 Kopenhagen, Dänemark, um die Einwilligungen
                der Nutzer zur Datenverarbeitung zu verwalten. Dies erfolgt im
                Rahmen der rechtlichen Anforderungen nach Art. 6 Abs. 1 c) DSGVO
                in Verbindung mit Art. 7 Abs. 1 DSGVO.
                <br />
                <br />
                Usercentrics bietet eine Consent Management Platform (CMP) als
                Software-as-a-Service (SaaS)-Lösung an. Diese Lösung wird
                verwendet, um die Einwilligungen der Nutzer unserer Webseite zu
                sammeln und zu dokumentieren. Durch die Nutzung des CMP werden
                Elemente auf unserer Website, wie Skripte, Bilder, iFrames usw.,
                je nach erteilter Einwilligung des Nutzers blockiert oder
                freigegeben, wenn die Website geöffnet wird. Elemente, die eine
                Einwilligung erfordern, werden erst freigegeben, nachdem der
                Nutzer seine Einwilligung erteilt hat.
                <br />
                <br />
                Elemente auf unserer Webseite, die eine Einwilligung erfordern,
                werden regelmäßig von Usercentrics automatisch erkannt und
                entsprechend der erforderlichen Einwilligungskategorien
                kategorisiert.
              </p>

              <p>
                Folgende Daten der Nutzer unserer Webseite werden bei der
                Nutzung des CMP verarbeitet:{" "}
              </p>
              <ul>
                <li>
                  Einwilligungsdaten: Einwilligungs-ID (Anonymer, zufällig
                  generierter und verschlüsselter Schlüssel, erforderlich zur
                  Nachverfolgung, um Ihre Cookie-Präferenzen bei zukünftigen
                  Besuchen zu berücksichtigen und zum Nachweis Ihrer
                  Einwilligung), Einwilligungsstatus (erforderlich, um gemäß der
                  Auswahl des Nutzers Elemente zu blockieren oder freizugeben),
                  Einwilligungsdatum und -zeit{" "}
                </li>
                <li>
                  Besuchte URL – Erforderlich, um zu dokumentieren, wo ein
                  Nutzer seine Einwilligung erteilt hat.
                </li>
                <li>
                  Nutzersprache – Erforderlich für die Spracheinstellungen des
                  Cookie-Banners.{" "}
                </li>
                <li>
                  User-Agent (Browseranbieter und Version, Betriebssystem) –
                  Erforderlich, um echten Traffic von synthetisch erzeugtem
                  Traffic zu unterscheiden.{" "}
                </li>
                <li>
                  IP-Adresse – Erforderlich, um den Cookie-Banner und die
                  Webseite auf Ihrem Endgerät anzuzeigen und echte von
                  synthetisch erzeugten Einwilligungen zu trennen.
                </li>
                <li>
                  Geostandort – Erforderlich, um sicherzustellen, dass die
                  angezeigten Einwilligungsbanner dem geografischen Standort und
                  der rechtlichen Zuständigkeit des Nutzers entsprechen.
                </li>
              </ul>

              <p>
                Die Rechtsgrundlagen der Verarbeitung sind Art 6 Abs. 1 a, b, c
                und f DSGVO i.V.m. Art. 7 DSGVO.
                <br />
                <br />
                Usercentrics verarbeitet keine Einwilligungsdaten, bevor eine
                ordnungsgemäße Einwilligung vom Endnutzer erhalten wurde. Das
                CMP verarbeitet notwendige Informationen wie die IP-Adresse des
                Endnutzers, um das CMP-Banner anzuzeigen und die Option zur
                Einholung der Einwilligung bereitzustellen. Diese Informationen
                werden jedoch nach Angaben von Usercentrics nach der ersten
                Verarbeitung nicht gespeichert.
                <br />
                <br />
                Nachdem der Endnutzer unserer Webseite seine Einwilligung
                erteilt hat, verarbeitet und speichert Usercentrics die oben
                aufgeführten Daten in unserem Auftrag („Auftragsverarbeiter“).
                Mit Usercentrics wurde ein Auftragsverarbeitungsvertrag gemäß
                Art. 28 DSGVO geschlossen. Usercentrics ist zur Vertraulichkeit
                und zum Schutz Ihrer Daten verpflichtet.
                <br />
                <br />
                Das Einwilligungs-Cookie wird ausschließlich auf der Website
                verwendet, auf der der Nutzer seine Einwilligung erteilt hat,
                und wird niemals auf Drittseiten gelesen oder übertragen.
                Usercentrics speichert ein Protokoll der erteilten
                Einwilligungen in einer Datenbank, damit wir auf Anfrage einen
                Nachweis oder generell das Protokoll der Einwilligungen
                bereitstellen können (Art. 6 Abs. 1 c DSGVO).
                <br />
                <br />
                Weitere Informationen finden Sie in der
                Cookiebot-Datenschutzrichtlinie unter:
                https://www.cookiebot.com/de/privacy-policy/.
              </p>

              <h3>4.Intelligenter Telefonassistent, Büroanwendungen </h3>

              <p>
                Wir verwenden intelligente Telefonassistenten. Diese werden von
                der Aaron GmbH, Ritterstraße 6, 10969 Berlin und sipgate GmbH,
                Gladbacher Str. 74, 40219 Düsseldorf („aaron.ai“, „sipgate“ oder
                „Auftragsverarbeiter“) bereitgestellt.
                <br />
                <br />
                Mit Hilfe von aaron.ai können Sie uns jederzeit erreichen. Der
                Assistent nimmt Ihren Anruf entgegen und fragt die nötigen
                Informationen zu Ihrem Anliegen ab (Telefonnummer, Name,
                Geburtsdatum, Krankenversicherung, Grund der Kontaktaufnahme,
                z.B. Anlass für Termin, Dauer und Art der Symptome, und/oder
                sonstige Patienten- und Gesundheitsdaten, die Sie freiwillig
                kommunizieren). Unser Praxisteam erhält diese Informationen auf
                dem Computer und kann sich schnellstmöglich bei Ihnen melden.
                <br />
                <br />
                Zudem nutzen wir den Cloud-Telefonie-Anbieter Sipgate zur
                Verwaltung aller Anrufe sowie Nachrichten wie SMS und
                Faxsendungen. Unsere Telefonanlage wird als Cloud-Anwendung auf
                Servern in der EU in unserem Auftrag gehostet. Anrufe, sowohl
                eingehend als auch ausgehend, werden dabei über das Internet
                abgewickelt. Dadurch werden Ihre Daten, wie beispielsweise
                Verkehrsdaten, Inhaltsdaten, Kontakt-, Personenstamm- und
                Kommunikationsdaten (Name, Adresse, Telefonnummer, Faxnummer,
                E-Mail-Adresse), in den Systemen unseres Dienstleisters sipgate
                verarbeitet. Im Rahmen eines Auftragsverarbeitungsvertrags gemäß
                Art. 28 DSGVO bereitet Sipgate Daten wie Anrufe, Nachrichten,
                Faxsendungen und Transkriptionen eingegangener Voicemails im
                eingeloggten Nutzerbereich für uns auf.
                <br />
                <br />
                Sowohl mit aaron.ai als auch mit sipgate wurde ein
                Auftragsverarbeitungsvertrag gemäß Art. 28 DSGVO geschlossen.
                Aaron.ai und sipgate sind zur Vertraulichkeit und zum Schutz
                Ihrer Daten verpflichtet.
                <br />
                <br />
                Für die Bearbeitung von Dokumenten, die Aufrechterhaltung der
                E-Mail-Kommunikation sowie die Bereitstellung von
                Online-Meetings, Videokonferenzen und/oder Webinaren verwenden
                wir Microsoft 365. Der Anbieter dieses Dienstes ist Microsoft
                Ireland Operations Ltd, mit Sitz in Dublin, Irland. Bei der
                Nutzung von Microsoft-Diensten, wie z.B. dem Kontakt über E-Mail
                oder der Teilnahme an Microsoft-Teams-Besprechungen, werden von
                Microsoft bestimmte Daten, darunter Kontakt- und
                Systeminformationen, erfasst. Die Grundlage für die
                Datenverarbeitung bildet eine vertragliche Vereinbarung zwischen
                Microsoft und uns, gemäß dem Microsoft Products and Services
                Data Protection Addendum. Weitere Informationen hierzu finden
                Sie auf der Webseite von Microsoft under
                https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA.
                <br />
                <br />
                Die Verarbeitung Ihrer Daten erfolgt zur Bearbeitung Ihrer
                Anfragen oder zur Erfüllung des Behandlungsvertrags (z. B.
                Terminvereinbarungen) gemäß Art. 6 Abs. 1 b) DSGVO, aufgrund
                ihrer Einwilligung zur Erinnerung an Termine gemäß Art. 6 Abs. 1
                a) DSGVO sowie zur Gesundheitsvorsorge gemäß Art. 9 Abs. 2 h)
                DSGVO.
                <br />
                <br />
                Ihre Daten werden gelöscht, sobald der Zweck der Speicherung
                entfällt und keine gesetzlichen Aufbewahrungspflichten bestehen.
                Die gespeicherten personenbezogenen Daten und
                Anrufaufzeichnungen werden vom Auftragsverarbeiter aaron.ai nach
                60 Tagen gelöscht.
                <br />
                <br />
                Die Daten können für einen Zeitraum von 10 Jahren ab dem Datum
                der letzten Behandlung des Patienten aufbewahrt werden. Weitere
                Informationen zum Datenschutz bei aaron.ai und sipgate finden
                Sie in den Datenschutzerklärungen der Anbieter unter
                www.aaron.ai/datenschutz und www.sipgate.de/datenschutz.
              </p>

              <h3>5. E-Mail, Kontaktformular, Smarter Web Assistent („SWA“)</h3>
              <p>
                Auf unserer Website steht Ihnen ein Kontaktformular zur
                Verfügung, über das Sie uns elektronisch kontaktieren können.
                Alternativ können Sie uns auch über die angegebene
                E-Mail-Adresse erreichen. Wenn Sie uns eine Nachricht senden,
                werden die von Ihnen gemachten Angaben inklusive Ihrer
                Kontaktdaten zur Bearbeitung Ihrer Anfrage und für eventuelle
                Rückfragen oder zur Erfüllung des Behandlungsvertrags bei uns
                gemäß Art. 6 Abs. 1 b) DSGVO gespeichert.
                <br />
                <br />
                Das Kontaktformular, der sogenannte „Smarte Web Assistent“
                (SWA), wird von aaron.ai zur Verfügung gestellt. Bei der Nutzung
                dieser Webapplikationen verarbeitet aaron.ai folgende
                personenbezogene Daten von Nutzern: IP-Adresse, E-Mail,
                Nutzungsdaten / Log Files und Zeit. Die Speicherung dieser Daten
                erfolgt zum einen, um Ihre Anfrage bearbeiten zu können (Art. 6
                Abs. 1 b) DSGVO), und zum anderen zur Wahrung unseres
                berechtigten Interesses an der Speicherung (Art. 6 Abs. 1 f)
                DSGVO, aus Sicherheitsgründen und um Missbrauchsfälle
                aufzudecken).
                <br />
                <br />
                Mit aaron.ai wurde ein Auftragsverarbeitungsvertrag gemäß Art.
                28 DSGVO geschlossen. Aaron.ai ist zur Vertraulichkeit und zum
                Schutz Ihrer Daten verpflichtet. Die beim Auftragsverarbeiter
                gespeicherten personenbezogenen Daten werden nach 60 Tagen
                gelöscht. Im Übrigen werden die Daten gelöscht, sobald der Zweck
                der Speicherung entfällt und keine gesetzlichen
                Aufbewahrungspflichten bestehen. Die Daten können für einen
                Zeitraum von 10 Jahren ab dem Datum der letzten Behandlung des
                Patienten aufbewahrt werden.
              </p>

              <h3>6.Online Terminplanungssystem doctolib</h3>
              <p>
                Für eine effiziente Terminverwaltung setzen wir das
                Terminplanungssystem der Doctolib GmbH, Mehringdamm 51, 10961
                Berlin („doctolib“ oder „Auftragsverarbeiter“) ein. Dieses
                System ermöglicht uns, eine moderne Kalenderlösung zu nutzen,
                und bietet unseren Patienten die Möglichkeit, über die Webseite
                https://www.doctolib.de/ Termine mit uns online zu vereinbaren.
                Doctolib fungiert in diesem Zusammenhang als unser
                Auftragsverarbeiter.
                <br />
                <br />
                Wir verwenden doctolib für die einheitliche Verwaltung aller
                Patiententermine sowie für die (patientenbezogene) Kommunikation
                innerhalb der Praxis und teilweise mit anderen
                Heilberufler*innen. Folgende personenbezogene Daten werden zur
                Terminvereinbarung in das Doctolib-System eingetragen: Name,
                Vorname, Geburtsdatum, Adresse, Telefonnummer, E-Mail-Adresse,
                Hausarzt, Krankenkassenstatus, überweisender Arzt, der Grund für
                den Besuch sowie die Terminverlaufshistorie. Die Erhebung dieser
                Daten dient der Terminverwaltung. Rechtsgrundlage für die
                Datenverarbeitung ist Art. 6 Abs. 1 b) DSGVO (Bearbeitung Ihrer
                Anfrage, Erfüllung des Behandlungsvertrags), Art. 6 Abs. 1 a)
                DSGVO (Einwilligung zur Erinnerung an Termine per SMS und
                E-Mail) sowie Art. 9 Abs. 2 h) DSGVO (Gesundheitsvorsorge).
                <br />
                <br />
                Mit doctolib wurde ein Auftragsverarbeitungsvertrag gemäß Art.
                28 DSGVO geschlossen. Doctolib ist zur Vertraulichkeit und zum
                Schutz Ihrer Daten verpflichtet.
                <br />
                <br />
                Um Terminversäumnisse zu vermeiden, bieten wir Ihnen mit Ihrer
                Zustimmung an, Sie per SMS oder E-Mail an bevorstehende Termine
                zu erinnern. Falls Sie keine Erinnerungen mehr wünschen, können
                Sie uns jederzeit darüber informieren, damit wir diese Funktion
                für Sie deaktivieren. Sowohl die Zustimmung als auch deren
                Widerruf können mündlich erfolgen.
                <br />
                <br />
                Für die Buchung von Terminen über die Webseite von doctolib ist
                ein persönliches Nutzerkonto erforderlich. In diesem
                Zusammenhang gelten die Datenschutzhinweise von doctolib.
                Doctolib ist verantwortlich für die Datenverarbeitung im Rahmen
                der Kontoerstellung. Rechtsgrundlage ist Art. 6 Abs. 1 b) (zur
                Bearbeitung Ihrer Anfrage) und Art. 9 Abs. 2 h) DSGVO
                (Gesundheitsvorsorge).
                <br />
                <br />
                Wir haben auf unserer Webseite eine Schaltfläche integriert, die
                es Ihnen ermöglicht, Termine über die Doctolib-Webseite
                (https://www.doctolib.de/) zu buchen. Sobald Sie auf die
                Schaltfläche „Termin buchen“ klicken, werden Sie auf die
                Webseite von doctolib weitergeleitet, wo Sie einen Termin
                vereinbaren können.
                <br />
                <br />
                Die bei doctolib gespeicherten Daten werden standardmäßig fünf
                Jahre aufbewahrt. Für Verwaltungszwecke unserer Praxis können
                die in der Doctolib-Plattform aufgezeichneten Daten für einen
                Zeitraum von zehn Jahren ab dem Datum der letzten Behandlung des
                Patienten aufbewahrt werden.
              </p>

              <h3>7. Bewerbungen</h3>
              <p>
                Bei Bewerbungen werden Ihre Daten verarbeitet, um das
                Bewerbungsverfahren durchzuführen und eine Entscheidung über ein
                mögliches Beschäftigungsverhältnis zu treffen. Dabei nutzen wir
                die von Ihnen bereitgestellten Informationen ausschließlich, um
                Ihre fachliche Eignung zu bewerten und Sie zu kontaktieren.
                <br />
                <br />
                Die Verarbeitung erfolgt im Rahmen vorvertraglicher Maßnahmen,
                die auf Ihre Bewerbung hin notwendig sind (Art. 6 Abs. 1 b)
                DSGVO in Verbindung mit § 26 BDSG). Sollte Ihre Bewerbung für
                andere vakante Positionen berücksichtigt werden, basiert dies
                auf Ihrer ausdrücklichen Zustimmung (Art. 6 Abs. 1 a) DSGVO).
                Zudem können personenbezogene Daten verarbeitet werden, wenn
                dies zur Erfüllung rechtlicher Pflichten (Art. 6 Abs. 1 c)
                DSGVO) oder zur Verteidigung gegen rechtliche Ansprüche (Art. 6
                Abs. 1 f) DSGVO) erforderlich ist.
                <br />
                <br />
                Innerhalb unserer Praxis haben nur die Stellen Zugriff auf Ihre
                Daten, die diese zur Erfüllung der vertraglichen und
                gesetzlichen Pflichten benötigen. Technische Dienstleister, die
                Zugriff auf Ihre Daten haben, sind durch entsprechende
                Auftragsverarbeitungsverträge zur Vertraulichkeit und zum
                Datenschutz verpflichtet.
                <br />
                <br />
                Im Regelfall stellen Sie uns Ihre Daten direkt zur Verfügung. In
                bestimmten Fällen können wir jedoch auch Daten von Dritten
                erhalten, beispielsweise von Personalvermittlern. Des Weiteren
                verarbeiten wir berufsbezogene, öffentlich zugängliche
                Informationen, wie Profile in beruflichen Netzwerken (z.B. Xing,
                LinkedIn).
                <br />
                <br />
                Bei Ablehnung Ihrer Bewerbung werden die Daten drei Monate nach
                Bekanntgabe der Entscheidung gelöscht. Wenn Sie einer weiteren
                Speicherung für andere relevante Positionen zustimmen, werden
                die Daten maximal ein Jahr aufbewahrt, es sei denn, Sie
                widerrufen Ihre Einwilligung zuvor.
                <br />
                <br />
                Es wird keine automatisierte Entscheidungsfindung oder Profiling
                im Sinne des Art. 22 DSGVO im Bewerbungsverfahren eingesetzt.
              </p>

              <h3>8. Social-Media-Kanäle</h3>
              <p>
                Im Folgenden erhalten Sie Informationen zu unseren
                Praxisprofilen auf verschiedenen Social-Media-Plattformen.
              </p>
              <h4>Facebook</h4>
              <p>
                Wir betreiben eine Praxisseite auf der Plattform Facebook, die
                von Meta Platforms Ireland Limited („Meta“), 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Irland, im europäischen
                Raum bereitgestellt wird.
                <br />
                <br />
                Die Nutzung unserer Facebook-Seite erfolgt auf eigene
                Verantwortung der Nutzer, insbesondere bei der Interaktion mit
                Funktionen wie Kommentieren, Teilen oder Liken.
                <br />
                <br />
                In Fällen, in denen Meta die alleinige Kontrolle über die Zwecke
                und Mittel der Datenverarbeitung im Zusammenhang mit unserer
                Facebook-Seite hat, ist Meta die alleinige Verantwortliche für
                die Verarbeitung. Die Betreiberin von Facebook, Meta,
                entscheidet eigenständig über die Zwecke und Mittel der
                Verarbeitung personenbezogener Daten der Facebook-Nutzer.
                Weitere Details zur Datenerfassung und -verarbeitung durch
                Facebook können Sie in den Datenschutzrichtlinien von Facebook
                nachlesen. Darin informiert Facebook über die rechtlichen
                Grundlagen der Datenverarbeitung sowie darüber, wie Sie Ihre
                Rechte nach der DSGVO gegenüber Facebook geltend machen können.
                <br />
                <br />
                Dies betrifft insbesondere allgemeine Informationen, die von
                Facebook erfasst werden, wie Ihre IP-Adresse,
                Account-Informationen und Gerätedaten. Es werden auch Cookies
                verwendet, die auf Ihrem Gerät gespeichert werden, um Daten zu
                erfassen. Bei der Nutzung der interaktiven Funktionen von
                Facebook (z. B. Kommentare, „Gefällt mir“-Angaben) werden
                weitere personenbezogene Daten der Nutzer erfasst und gemäß den
                Datenschutzrichtlinien von Facebook verarbeitet. Wir möchten Sie
                darauf aufmerksam machen, dass wir keinen Einfluss auf die durch
                Meta im Zusammenhang mit Cookies vorgenommene Datenverarbeitung
                haben.
                <br />
                <br />
                Meta Platforms Ireland Limited ist der Hauptempfänger Ihrer
                Daten. Ein Datentransfer in die USA kann nicht ausgeschlossen
                werden, da die Muttergesellschaft, Meta Platforms Inc., ihren
                Sitz in den USA hat. Die Übertragung von Daten in die USA
                erfolgt auf Grundlage des Angemessenheitsbeschlusses der
                Europäischen Kommission zum Data Privacy Framework, unter dem
                Meta zertifiziert ist. Dadurch wird der Transfer auf einer
                rechtlich abgesicherten Grundlage durchgeführt.
                <br />
                <br />
                Wenn im Zusammenhang mit unserer Facebook-Seite oder deren
                Inhalten personenbezogene Daten sowohl von uns als auch von Meta
                verarbeitet werden und wir gemeinsam über die Zwecke und Mittel
                dieser Verarbeitung entscheiden, übernehmen Meta und wir
                gemeinsam die Verantwortung für die Datenverarbeitung.
                <br />
                <br />
                Wir gehen davon aus, dass diese gemeinsame Verantwortung sich
                ausschließlich auf die Verarbeitung von sogenannten
                „Page-Insights“ erstreckt. „Page-Insights“ sind Analysedienste,
                die von Meta bereitgestellt werden. Wie in Metas
                Datenschutzrichtlinie erläutert, sammelt und verwendet Meta dazu
                Informationen zu Ihrer Interaktion mit der jeweiligen
                Facebook-Seite. Wir haben keinen Zugriff auf personenbezogene
                Daten einzelner Nutzer, sondern können lediglich die genannten
                Statistiken einsehen.
                <br />
                <br />
                In der Ergänzung zu den Richtlinien von Meta für Seiten, Gruppen
                und Veranstaltungen sowie zu den Nutzungsbedingungen haben Meta
                und wir gemäß Art. 26 Abs. 1 S. 2 DSGVO festgelegt, dass Meta
                die primäre Verantwortung für die Erfüllung aller
                Verpflichtungen in Bezug auf die Verarbeitung von Insights-Daten
                übernimmt, insbesondere hinsichtlich der Wahrnehmung der Rechte
                der Betroffenen gemäß der DSGVO. Durch den Betrieb unserer
                Facebook-Seite stimmen wir dieser Vereinbarung stillschweigend
                zu; die Vereinbarung finden Sie unter folgendem Link:
                https://www.facebook.com/legal/terms/page_controller_addendum.
                <br />
                <br />
                Wenn Sie uns über unsere Facebook-Seite personenbezogene Daten
                zur Verfügung stellen und wir allein über die Zwecke und Mittel
                der Verarbeitung entscheiden, sind wir für die Verarbeitung
                Ihrer Daten verantwortlich.
                <br />
                <br />
                Unsere Facebook-Seite ermöglicht es Ihnen, auf Beiträge zu
                reagieren, Kommentare zu hinterlassen, eigene Beiträge zu
                erstellen und uns private Nachrichten zu senden. Bitte achten
                Sie darauf, welche personenbezogenen Daten Sie über Facebook mit
                uns teilen. Wenn Sie nicht möchten, dass persönliche Daten, die
                Sie an uns übermitteln, von Facebook verarbeitet werden, können
                Sie alternativ über andere Kontaktmöglichkeiten mit uns in
                Verbindung treten.
                <br />
                <br />
                Neben den von Ihnen geteilten Inhalten können uns, abhängig von
                Ihren Privatsphäre-Einstellungen, zusätzliche Informationen zu
                Ihrem Profil, Ihren „Gefällt mir“-Angaben und Ihren Beiträgen
                zugänglich sein.
                <br />
                <br />
                Die Verarbeitung Ihrer personenbezogenen Daten erfolgt entweder
                auf Ihre Anfrage hin zur Durchführung vorvertraglicher Maßnahmen
                oder zur Erfüllung eines Behandlungsvertrags gemäß Art. 6 Abs. 1
                b) DSGVO sowie auf Grundlage berechtigter Interessen gemäß Art.
                6 Abs. 1 f) DSGVO. Unser Interesse besteht vorwiegend darin,
                unsere Reichweite zu erhöhen, Zielgruppen besser zu erreichen
                und unser Praxisimage zu pflegen.
                <br />
                <br />
                Ihre Daten werden, soweit möglich, gelöscht, wenn wir den
                Betrieb unserer Facebook-Seite einstellen. Eine darüber
                hinausgehende Speicherung durch Meta erfolgt ausschließlich auf
                Grundlage der Bestimmungen von Metas Datenschutzrichtlinie und
                Nutzungsbedingungen.
              </p>

              <h4>Instagram</h4>
              <p>
                Wir betreiben eine Praxisseite auf Instagram, einem sozialen
                Netzwerk für das Teilen von Bildern und Videos, welches
                ebenfalls von Meta betrieben wird.
                <br />
                <br />
                Die Nutzung unserer Instagram-Seite erfolgt auf eigene
                Verantwortung der Nutzer, insbesondere bei der Interaktion mit
                Funktionen wie Kommentieren, Teilen oder Liken.
                <br />
                <br />
                Wenn Sie unsere Instagram-Seite besuchen, kann Meta Cookies
                einsetzen, um Informationen über die Nutzer und deren Verhalten
                auf Instagram zu sammeln. Instagram kann beim Besuch unserer
                Seite unter anderem Ihre IP-Adresse erfassen. Weitere
                Informationen zu den Instagram-Cookies und Details zur
                Datenverarbeitung auf Instagram finden Sie in den
                Instagram-Cookie-Richtlinien und
                Instagram-Datenschutzrichtlinien.
                <br />
                <br />
                Wir haben keinen Einfluss auf die Datenverarbeitung durch Meta.
                Die auf Instagram gesammelten Daten werden von Meta Platforms
                Ireland Limited verarbeitet. Da Meta Platforms Inc. in den USA
                ansässig ist, ist ein Datentransfer in die USA möglich. Für die
                Übermittlung personenbezogener Daten in die USA liegt ein
                Angemessenheitsbeschluss der EU-Kommission vor, der für
                Unternehmen mit einer Zertifizierung nach dem Data Privacy
                Framework gilt. Meta ist nach diesem Framework zertifiziert und
                stützt sich bei der Datenübertragung auf diesen Beschluss.
                <br />
                <br />
                Unsere Instagram-Seite ermöglicht es Ihnen, auf unsere Beiträge
                zu reagieren, Kommentare zu hinterlassen und uns private
                Nachrichten zu senden. Zusätzlich zu den von Ihnen übermittelten
                Inhalten sind für uns abhängig von Ihren
                Privatsphäre-Einstellungen Informationen zu Ihrem Profil, Ihren
                Gefällt-mir-Angaben und Ihren Beiträgen sichtbar. Die
                Verarbeitung personenbezogener Daten erfolgt zur Bearbeitung
                Ihrer Anfrage, Aufgrund Ihrer Einwilligung oder auf Basis des
                berechtigten Interesses gemäß Art. 6 Abs. 1 a), b) und f) DSGVO.
                Dieses Interesse umfasst Marketingmaßnahmen wie die Verbesserung
                der Sichtbarkeit, Erreichbarkeit unserer Zielgruppen,
                Imagepflege und Informationsverbreitung.
                <br />
                <br />
                Diese Daten werden, soweit es uns möglich ist, bei Einstellung
                des Betriebs unserer Instagram-Seite gelöscht. Sofern eine
                darüber hinausgehende Speicherung dieser Daten durch Meta
                erfolgt, richtet sich diese ausschließlich nach den Bestimmungen
                der Instagram-Datenschutzrichtlinie und den
                Instagram-Nutzungsbedingungen.
                <br />
                <br />
                Unsere Instagram-Seite ist als Business-Profil eingerichtet,
                wodurch wir von Meta anonymisierte Seitenstatistiken erhalten.
                Diese Statistiken geben uns Einblicke in das Verhalten und die
                Interaktionen der Besucher unserer Instagram-Seite sowie deren
                Inhalte. Wir haben keinen Einfluss auf die Zwecke und Mittel der
                Verarbeitung personenbezogener Daten durch Meta, die zur
                Erstellung dieser Statistiken verwendet werden, und können
                diesen Prozess auch nicht verhindern. Ein Rückschluss auf
                Personen, die diese Ereignisse ausgelöst haben, ist für uns
                nicht möglich. Bei Fragen zu den personenbezogenen Daten, die
                Meta zur Erstellung der Statistiken verarbeitet, können Sie sich
                direkt an den Datenschutzbeauftragten von Meta wenden.
              </p>

              <h3>9. Google Maps</h3>
              <p>
                Auf unserer Website verwenden wir möglicherweise den
                Kartendienst Google Maps von Google Ireland Limited, Gordon
                House, Barrow Street, Dublin 4, Irland („Google“), um
                interaktive Karten anzuzeigen, sofern Sie uns Ihre Zustimmung
                dazu erteilt haben.
                <br />
                <br />
                Damit die Karten korrekt angezeigt werden können, wird Ihre
                IP-Adresse von Google erfasst und möglicherweise auf Server in
                den USA übertragen und dort gespeichert. Das Mutterunternehmen
                von Google in den USA ist die Google Inc., 1600 Amphitheatre
                Parkway, Mountain View, CA 94043, USA. Zusätzlich kann Google
                Informationen über Ihre Nutzung von Google Maps sammeln. Wir
                haben keine Kontrolle über die Erhebung, Speicherung oder
                Nutzung dieser Daten durch Google und keinen Zugriff darauf. Für
                die Übermittlungen personenbezogener Daten in die USA besteht
                ein Angemessenheitsbeschluss der Europäischen Kommission zum
                sog. EU-US Data Privacy Framework. Google LLC ist
                DPF-zertifiziert und Google kann sich bei der Datenübertragung
                auf diesen Beschluss stützen.
                <br />
                <br />
                Der Einsatz von Google Maps dient der einfachen Auffindbarkeit
                der auf der Website angegebenen Orte. Dies erfolgt aufgrund der
                von Ihnen erteilten Einwilligung, Art. 6 Abs. 1 a) DSGVO. Zudem
                besteht ein berechtigtes Interesse an der Verarbeitung darin,
                die Webseite für Sie möglichst anschaulich und angenehm zu
                machen, Art. 6 Abs. 1 f) DSGVO.
                <br />
                <br />
                Bitte lesen Sie vor der Nutzung der Google Maps-Funktion die
                Datenschutzrichtlinie von Google
                https://www.google.de/intl/de/policies/privacy/ sorgfältig
                durch. Durch die Nutzung von Google Maps auf unserer Website
                erklären Sie sich außerdem mit den Nutzungsbedingungen von
                Google einverstanden.
              </p>

              <h3>10. Google Analytics</h3>
              <p>
                Unsere Website verwendet Google Analytics, einen
                Webanalysedienst von Google Ireland Limited, Gordon House,
                Barrow Street, Dublin 4, Irland („Google“). Google Analytics
                setzt ebenfalls Cookies ein, um die Nutzung der Website zu
                analysieren. Dabei können folgende Daten erhoben werden:
                Spracheinstellungen, Betriebssystem, Art des Geräts (PC, Tablet
                oder Smartphone), Browser und installierte Add-ons,
                Besucherquellen (z. B. soziale Netzwerke, Suchmaschinen oder
                verweisende Webseiten), Aktivitäten auf der Website (z. B.
                heruntergeladene Dateien, angesehene Videos, angeklickte
                Werbebanner), sowie der Verlauf und die Dauer des
                Webseitenbesuchs.
                <br />
                <br />
                Wir nutzen die neueste Version von Google Analytics (4). Nach
                Informationen von Google werden in Google Analytics 4
                IP-Adressen weder protokolliert noch gespeichert. Google
                verwendet die IP-Adressen jedoch vorübergehend, um Standortdaten
                abzuleiten, und entfernt diese sofort danach. Alle IP-Adressen,
                die von Nutzern in der EU erfasst werden, werden also gelöscht,
                bevor sie beispielsweise auf einem Server gespeichert werden
                können.
                <br />
                <br />
                Wir haben mit Google einen Auftragsverarbeitungsvertrag gemäß
                Art. 28 DSGVO abgeschlossen. Die durch das Cookie erzeugten
                Informationen über Ihre Nutzung der Website können an Server von
                Google in den USA übertragen und dort gespeichert werden. Für
                die Übermittlung personenbezogener Daten in die USA besteht ein
                Angemessenheitsbeschluss der Europäischen Kommission zum
                sogenannten EU-US Data Privacy Framework. Google LLC ist
                DPF-zertifiziert und kann sich auf diesen Beschluss stützen.
                <br />
                <br />
                Google verwendet diese Informationen, um Berichte über die
                Websiteaktivitäten für uns zu erstellen und um weitere
                Dienstleistungen im Zusammenhang mit der Website- und
                Internetnutzung anzubieten. Unter bestimmten gesetzlichen
                Voraussetzungen oder im Rahmen von Auftragsverarbeitungen kann
                Google diese Informationen auch an Dritte weitergeben. Wenn
                Google Dritte beauftragt, ist Google nach dem mit uns
                abgeschlossenen Auftragsverarbeitungsvertrag verpflichtet, durch
                einen schriftlichen Vertrag mit dem Dritten sicherzustellen,
                dass die Datenschutzpflichten, wie in Art. 28 Abs. 3 DSGVO
                erwähnt, dem Unterauftragsverarbeiter auferlegt werden.
                <br />
                <br />
                Die Nutzung von Google Analytics-Cookies erfolgt aufgrund der
                von Ihnen erteilten Einwilligung gemäß Art. 6 Abs. 1 a) i.V.m.
                Art. 7 DSGVO. Wenn Sie keine Einwilligung erteilt haben, ist
                Google Analytics deaktiviert. Sie können Ihre Einstellungen
                jederzeit in der von uns verwendeten Consent Management Platform
                „Cookiebot“ einsehen und/oder ändern.
              </p>

              <h3>11. Google AdSense</h3>
              <p>
                Wir nutzen Google AdSense, ein Online-Werbeprogramm der Google
                Ireland Limited („Google“), Gordon House, Barrow Street, Dublin
                4, Irland. Google AdSense ermöglicht es uns, Werbeanzeigen in
                der Google-Suche oder auf Partnerwebseiten anzuzeigen, wenn
                Nutzer bestimmte Suchbegriffe eingeben (Keyword-Targeting).
                Zusätzlich können Anzeigen gezielt ausgespielt werden, basierend
                auf den bei Google verfügbaren Nutzerdaten wie
                Standortinformationen und Interessen (Zielgruppen-Targeting).
                <br />
                <br />
                Wir, als Betreiber dieser Website, können die Ergebnisse dieser
                Anzeigenkampagnen analysieren, beispielsweise indem wir prüfen,
                welche Suchbegriffe zu unseren Anzeigen geführt haben und wie
                viele Klicks generiert wurden.
                <br />
                <br />
                Die durch das Cookie erzeugten Informationen über Ihre Nutzung
                der Website können an Server von Google in den USA übertragen
                und dort gespeichert werden. Ihre IP-Adresse wird nach
                Informationen von Google weder protokolliert noch gespeichert.
                Für die Übermittlungen personenbezogener Daten in die USA
                besteht ein Angemessenheitsbeschluss der Europäischen Kommission
                zum sog. EU-US Data Privacy Framework. Google LLC ist
                DPF-zertifiziert und kann sich auf diesen Beschluss stützen.
                <br />
                <br />
                Mit Google wurde ein Auftragsverarbeitungsvertrag gemäß Art. 28
                DSGVO abgeschlossen. Die Nutzung von Google AdSense erfolgt
                aufgrund der von Ihnen erteilten Einwilligung gemäß Art. 6 Abs.
                1 a i.V.m. Art. 7 DSGVO. Wenn Sie keine Einwilligung erteilt
                haben, ist Google AdSense deaktiviert. Sie können Ihre
                Einstellungen jederzeit in der von uns verwendeten Consent
                Management Platform „Cookiebot“ einsehen und/oder ändern.
              </p>

              <h3>12. Schutz von Minderjährigen</h3>
              <p>
                Personen unter 18 Jahren sollten keine personenbezogenen Daten
                ohne die Zustimmung ihrer Eltern oder Erziehungsberechtigten an
                uns weitergeben. Wir fordern keine Daten von Kindern oder
                Jugendlichen an, und es ist nicht unsere Absicht, solche Daten
                zu sammeln oder an Dritte weiterzuleiten.
              </p>

              <h3>
                13. Rechte der Betroffenen Personen im Zusammenhang mit dem
                Datenschutz
              </h3>
              <p>
                Sie haben im Rahmen der Datenschutzgesetze folgende Rechte in
                Bezug auf Ihre personenbezogenen Daten:
              </p>
              <h4>Widerspruchsrecht gegen die Datenverarbeitung</h4>
              <p>
                Sie können jederzeit aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, der Verarbeitung Ihrer personenbezogenen
                Daten widersprechen, wenn diese auf Grundlage von Art. 6 Abs. 1
                f) DSGVO erfolgt. Nach einem Widerspruch verarbeiten wir Ihre
                Daten nur weiter, wenn wir zwingende schutzwürdige Gründe
                nachweisen können, die Ihre Interessen, Rechte und Freiheiten
                überwiegen oder die Verarbeitung der Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen dient.
              </p>
              <h4>Auskunftsrecht</h4>
              <p>
                Sie können gemäß den gesetzlichen Bestimmungen jederzeit
                Auskunft darüber verlangen, ob und welche personenbezogenen
                Daten von Ihnen gespeichert sind.
              </p>
              <h4>
                Recht auf Löschung, Berichtigung und Einschränkung der
                Verarbeitung
              </h4>
              <p>
                Auf Ihren Wunsch hin löschen, berichtigen oder schränken wir die
                Verarbeitung Ihrer Daten entsprechend den gesetzlichen
                Vorschriften ein.
              </p>
              <h4>Recht auf Datenübertragbarkeit</h4>
              <p>
                Sie haben das Recht, Ihre personenbezogenen Daten in einem
                strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten und diese an einen anderen Verantwortlichen zu
                übermitteln, sofern dies technisch möglich ist.
              </p>

              <h4>Beschwerderecht bei der Datenschutzaufsichtsbehörde</h4>
              <p>
                Sollten Sie Bedenken bezüglich der Verarbeitung Ihrer
                personenbezogenen Daten haben, können Sie sich an die zuständige
                Datenschutzaufsichtsbehörde wenden. Für uns ist dies:
              </p>

              <address>
                Der Landesbeauftragte für den Datenschutz Niedersachsen
                <br />
                Prinzenstraße 5<br />
                30159 Hannover
                <br />
                Telefon: 0511 120-4500
                <br />
                Fax: 0511 120-4599
                <br />
                E-Mail:{" "}
                <a href="mailto:poststelle@lfd.niedersachsen.de">
                  poststelle@lfd.niedersachsen.de
                </a>
              </address>

              <h4>Widerrufsrecht</h4>
              <p>
                Sie haben das Recht, eine erteilte Einwilligung zur Verarbeitung
                Ihrer personenbezogenen Daten jederzeit mit Wirkung für die
                Zukunft zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund
                der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt
                wird.
                <br />
                <br />
                Alle Rechte an dieser Website und deren Inhalte liegen bei den
                jeweiligen Urhebern.
              </p>
            </p>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
